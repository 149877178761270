// antStyle
.picker {
  :global {
    .ant-picker {
      border-radius: 16px !important;
      // padding: 0.75rem;
      color: red;
    }
    .ant-input-password,
    .ant-picker,
    input {
      border-radius: 16px;
      color: #6e7191;
      margin-left: 0.25rem !important;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }

    .ant-picker-range,
    .ant-picker,
    .ant-select-selector,
    .ant-input-password,
    .ant-select .ant-input {
      min-height: 50px !important;
      border-radius: 16px !important;
    }

    .ant-input-password,
    .ant-input {
      min-height: 50px !important;
      padding-left: 1rem !important;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      // margin: 0.5rem !important;
      margin: auto 7.5px;
    }
    .ant-select-item-option-content {
      // background-color: aqua !important;
      height: 200px !important;
    }
    .ant-select-selection-search {
      // padding-top: 0.5rem;
      margin-left: 0.75rem;
    }
    .ant-select-selection-overflow {
      margin-top: 0.75rem;
      position: relative;
      top: -0.325rem;
    }
    .ant-select-arrow {
      margin-right: 0.5rem;
    }
    .ant-picker-suffix {
      margin-right: 0.25rem;
    }
    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 16px !important;
      min-height: 55px !important;
    }
    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
      margin: 0rem 0.25rem !important;
    }
    .ant-select-single.ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      input {
      margin: 0.75rem 0rem !important;
    }
    .ant-btn {
      border: 1.5px solid #07a1977c;
    }
    .ant-btn:hover {
      border: 1.5px solid #07a197 !important;
    }
    .ant-btn-primary {
      border: 1.5px solid #fff !important;
    }
    .ant-btn-primary:hover {
      border: 1.5px solid #fff !important;
    }
  }
}

.modal {
  :global {
    .ant-modal-header,
    .ant-modal-footer {
      border: 0;
      border-radius: 10px;
    }
    .ant-select {
      width: max-content;
    }
    .ant-select-selection-overflow-item {
      padding: 3px 0 !important;
    }
    .ant-modal-content {
      border-radius: 16px !important;
      // background: #eff2f5;
    }
    .ant-btn-icon-only {
      height: 2rem !important;
    }
  }
}

@primary-color: #07A197;@menu-dark-bg: #272727;@layout-header-background: #272727;